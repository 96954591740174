<template>
  <div class="page1">
    <!--主体内容-->
    <div class="searchWrapper">
      <el-form
        :inline="true"
        ref="searchWrapper"
        label-position="right"
        :model="formInline"
        class="demo-form-inline"
        :rules="rule"
      >
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <div class="col_box">
          <div class="col_left">
            <!-- 放入搜索条件 -->
            <el-form-item :label="$t('searchModule.region')" prop="areaId">
              <a-cascader
                @change="handleAreaChange"
                ref="cascader"
              ></a-cascader>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Merchant_Name')">
              <el-select
                v-model.trim="formInline.operationId"
                filterable
                size="15"
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="value.operationName"
                  :value="value.operationId"
                  :key="value.operationId"
                  v-for="value in roleList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Road_name')">
              <my-component
                ref="parkInput"
                :areaIds="areaIds"
                :operationId="formInline.operationId"
                :dataSource="1"
                @valueChange="completeValue"
                slaveRelations="0,1"
              >
              </my-component>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
              <el-select
                v-model.trim="formInline.parkType"
                filterable
                placeholder=""
                size="10"
              >
                <el-option label="全部" value="-1"></el-option>
                <el-option
                  :label="park.desc"
                  :value="park.code"
                  :key="park.code"
                  v-for="park in parkType"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.phone_number')" prop="mobile" ref="mobile">
              <el-input
                size="12"
                :maxlength="11"
                v-model="formInline.mobile"
                placeholder="手机号"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.plate_number')" ref="plateNumber">
              <el-autocomplete
                ref="plateNumber"
                size="11"
                valueKey="plateNumber"
                class="inline-input"
                v-model="formInline.plateNumber"
                :fetch-suggestions="querySearchAsync"
                placeholder="车牌号"
                :maxlength="8"
                :trigger-on-focus="false"
                @select="handleSelect"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Berth_number')">
              <el-input
                placeholder="请输入泊位号"
                maxlength="30"
                v-model="formInline.berthCode"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Parking_record_ID')">
              <el-input
                v-model="formInline.parkRecordIds"
                :onkeyup="
                  (formInline.parkRecordIds = formInline.parkRecordIds.replace(
                    /[^\d]/g,
                    ''
                  ))
                "
                placeholder="停车记录ID"
                maxlength="19"
                type="text"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Presence_situation')">
              <el-select
                v-model.trim="formInline.recordState"
                filterable
                size="8"
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="item.desc"
                  :value="item.code"
                  :key="item.code"
                  v-for="item in recordList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Entry_and_exit_types')">
              <el-select
                v-model.trim="formInline.entryOrExit"
                filterable
                size="8"
              >
                <el-option
                  :label="item.desc"
                  :value="item.code"
                  :key="item.code"
                  v-for="item in ExitTypeList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.time_slot')">
              <timeRangePick
                @timeChange="timeChange"
                ref="timeRangePicker"
                :defaultRange="defaultRangeValue"
                :defalutDate="defalutDate"
              />
            </el-form-item>
          </div>
          <div class="col_right">
            <!-- 搜索按钮 -->
            <!-- 重置或清除按钮 -->
            <el-button
              v-if="$route.meta.authority.button.query"
              type="primary"
              icon="el-icon-search"
              @click="
                pageNum = 1;
                searParkRecordList();
              "
              :loading="loading"
              >{{ $t('button.search') }}</el-button
            >
            <el-button type="info" icon="el-icon-delete" @click="resetForm()"
              >{{ $t('button.reset') }}</el-button
            >
          </div>
        </div>
        <div class="col_box_boder"></div>
        <!-- 如果没有下面按钮就不需要 -->
        <div class="col_box h44">
          <div class="col_left">
            <!-- 其他按钮 -->
            <span class="statistics_title">数据统计</span>
          </div>
          <div class="col_right mbd4">
            <!-- 导出或下载按钮 -->
            <el-button
              v-if="$route.meta.authority.button.query"
              type="info"
              icon="el-icon-upload2"
              class="buts"
              @click="exportHandler"
              >{{ $t('button.export') }}</el-button
            >
          </div>
        </div>
        <tabCard :cardLists="cardLists"></tabCard>
      </el-form>
    </div>
    <!--列表区域-->
    <div class="tableWrapper bgFFF paddingB10">
      <el-table v-loading="loading" :data="tableData" style="width: 100%">
        <el-table-column
          align="center"
          :label="item.label"
          :min-width="item.width"
          v-for="item in tableCols"
          :key="item.prop"
          show-overflow-tooltip
        >
          <template slot="header" v-if="item.prop == 'originShouldPay'">
            <span>
              <!-- 订单金额 -->
              {{ $t("list.order_amount") }}
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('list.count_order_amount')"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </span>
          </template>
          <template slot="header" v-if="item.prop == 'discountMoney'">
            <div>
              {{ $t("list.discount_amount") }}
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('list.count_amount_calculation')"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </div>
          </template>
          <template slot="header" v-if="item.prop == 'shouldPayMoney'">
            <span>
              {{ $t("list.amount_receivable") }}
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('list.count_amount_receivable')"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </span>
          </template>
          <template slot="header" v-if="item.prop == 'agioPay'">
            <span>
              {{ $t("list.preferential_amount") }}
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('list.Coupon_payment_amount')"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </span>
          </template>
          <template slot="header" v-if="item.prop == 'refundMoney'">
            <span>
              {{ $t("list.Total_refund_amount") }}
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('list.count_total_refund_amount')"
                placement="top"
              >
                <i class="el-icon-question"></i>
              </el-tooltip>
            </span>
          </template>
          <template slot-scope="scope">
            <span
              v-if="item.prop == 'parkRecordId'"
              v-clipboard:copy="scope.row.parkRecordId"
              v-clipboard:success="onCopy"
            >
              <p>{{ scope.row.parkRecordId }}</p>
            </span>
            <span v-else-if="item.prop == 'parkTime'">
              <!-- <b v-if="scope.row.isPreRecord == 1" class="orderNumberStyle">预</b>{{ scope.row.parkTime }} -->
              {{ scope.row.parkTime }}
              <b v-if="scope.row.isPreRecord == 1" class="orderNumberStyle">{{
                $t("list.in_advance")
              }}</b>
              <b class="orderNumberStyle" v-if="scope.row.isEnergy == 1">{{
                $t("list.new")
              }}</b>
              <b
                class="orderNumberStyle"
                v-if="scope.row.isSpecialPlate == 1"
                >{{ $t("list.white") }}</b
              >
              <b
                class="orderNumberStyle"
                v-if="scope.row.freeChargeType == 0"
                >{{ $t("list.free") }}</b
              >
              <b
                class="orderNumberStyle"
                v-if="scope.row.freeChargeType == 1"
                >{{ $t("list.deformity") }}</b
              >
            </span>
            <span v-else>
              <span>
                {{
                  item.formatter
                    ? item.formatter(scope.row)
                    : scope.row[item.prop]
                }}
              </span>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          :label="$t('list.operation')"
          width="80"
          fixed="right"
          v-if="$route.meta.authority.button.detail"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="detailShow(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            v-if="total != 0"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <pic-detail
      :infoSize="4"
      :infoDetail="parkDetail"
      :rowData="rowData"
      :detailFlag="dialogVisible"
      :tableOrder="tableOrder"
      :tablePrepaid="tablePrepaid"
      :tablePrepaiddata="tablePrepaiddata"
      :parkRecordIdTable="parkRecordIdTable"
      @close="dialogVisible = false"
      :total="total1"
      :operationTable="operationTable"
      :operationData="operationData"
      :operationHistoryData="operationHistoryData"
      :operationTableHistory="operationTableHistory"
      :tableOrderdata="tableOrderdata"
      :parkRecordIdData="parkRecordIdData"
      @getOrder="clildrenHandleCurrentChange"
      :entryPic="entryPic"
      :exitPic="exitPic"
      :isCorrect="false"
      :isPreRecord="isPreRecord"
      :isManagebut="1"
      :chargeRecordList="chargeRecordList"
      :chargeOrderList="chargeOrderList"
    ></pic-detail>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import picDetail from "../../../components/picDetail/picDetail";
import { dateFormat, setIndex, exportExcelNew } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
import InfoList from "../../../components/infoList/infoList";
import tabCard from "@/components/tabCard/index.vue";
// import exportFile from "@/components/exportFile/exportFile.vue";
export default {
  name: "parkRecordTotal",
  data() {
    let checkParkNum = (rule, value, callback) => {
      let len = value.length;
      if (!value) {
        callback();
      } else if (len < 3) {
        callback(new Error("至少输入三个字符"));
      } else if (len > 3 && len <= 11) {
        callback();
      } else if (len > 11) {
        callback(new Error("最多输入十一个字符"));
      }
    };
    let checkPhone = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value) && value) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    return {
      cardLists: [
        { title: "停车记录", number: 0, unit: "个" },
        { title: "在场记录", number: 0, unit: "个" },
        { title: "订单金额", number: 0, unit: "元" },
        { title: "折扣金额", number: 0, unit: "元" },
        { title: "应收金额", number: 0, unit: "元" },
        { title: "优惠金额", number: 0, unit: "元" },
        { title: "实付金额", number: 0, unit: "元" },
        { title: "退款总计", number: 0, unit: "元" },
      ],
      operationHistoryData: [],
      chargeOrderList: [], // 充电订单记录
      chargeRecordList: [], // 充电违规记录
      isPreRecord: false, // 详情弹窗是否显示 预付费记录
      defalutDate: [],
      flag: false,
      plateNumber: "",
      rowData: {},
      infoSize: 4,
      isShow: false,
      dialogVisible: false,
      infoDetail: {},
      radio3: "停车记录信息",
      isPublic: true,
      index: 0,
      total: 0,
      total1: 0,
      entryPic: [],
      exitPic: [],
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      areaList2: [],
      parkList: [],
      parkType: [],
      merchantList: [],
      payTypeList: [],
      berthList: [],
      tableOrderdata: [],
      ExitTypeList: [],
      recordList: [
        { code: 1, desc: "未离场" },
        { code: 0, desc: "已离场" },
      ],
      roleList: [],
      rule: {
        plateNumber: [
          {
            validator: checkParkNum,
            trigger: "blur",
          },
        ],
        mobile: [
          {
            validator: checkPhone,
            trigger: "blur",
          },
        ],
      },
      tableCols: [
        {
          prop: "index",
          label: this.$t("list.index"),
          width: "70",
        },
        {
          prop: "parkRecordId",
          label: this.$t("list.Parking_record_ID"),
          width: "180",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "120",
        },
        // {
        //   prop: "areaName",
        //   label: this.$t("list.region"),
        //   width: "",
        // },
        {
          prop: "operationName",
          label: this.$t("list.Merchant_Name"),
          width: "200",
        },
        {
          prop: "parkName",
          label: this.$t("list.Road_name"),
          width: "100",
        },
        {
          prop: "parkTypeName",
          label: this.$t("list.Type_of_parking_lot"),
          width: "",
          formatter: (row) => {
            return this.parkType.find((v) => v.code === row.parkType).desc;
          },
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "120",
          formatter: (row, column) => {
            if (row.plateNumber.indexOf("无") != -1) {
              return "无牌车";
            } else {
              return row.plateNumber;
            }
          },
        },
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
          width: "120",
        },
        {
          prop: "strEntryTime",
          label: this.$t("list.entry_time"),
          width: "180",
        },
        {
          prop: "strExitTime",
          label: this.$t("list.leaving_time"),
          width: "180",
        },
        {
          prop: "parkTime",
          label: this.$t("list.Parking_duration"),
          width: "140",
        },
        {
          prop: "originShouldPay",
          label: this.$t("list.order_amount"),
          width: "100",
          formatter: (row, column) => {
            if (row.shouldPay) {
              return Number(row.shouldPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "discountMoney",
          label: this.$t("list.discount_amount"),
          width: "100",
          formatter: (row, column) => {
            if (row.discountMoney) {
              return Number(row.discountMoney / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "shouldPayMoney",
          label: this.$t("list.amount_receivable"),
          width: "100",
          formatter: (row, column) => {
            if (row.shouldPay) {
              return Number(row.shouldPayMoney / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "agioPay",
          label: this.$t("list.preferential_amount"),
          width: "100",
          formatter: (row, column) => {
            if (row.agioPay) {
              return Number(row.agioPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "actualPay",
          label: this.$t("list.Pay_amount"),
          width: "100",
          formatter: (row, column) => {
            if (row.actualPay) {
              return Number(row.actualPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "refundMoney",
          label: this.$t("list.Total_refund_amount"),
          width: "100",
          formatter: (row, column) => {
            // if (row.refundMoney) {
            let diff = row.refundMoney + row.refundAgioMoney;
            return Number(diff / 100).toFixed(2);
          },
        },
      ],
      tableOrderrefund: [
        {
          label: "支付来源",
          value: "devTypeDesc",
        },
        {
          label: this.$t("list.payment_time"),
          value: "payTime",
        },
        {
          label: "支付方式",
          value: "payTypeDesc",
        },
        {
          label: "已支付金额",
          value: "money",
          formatter: (row, column) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: "支付流水号",
          value: "paymentId",
        },
      ],
      operationTableHistory: [
        {
          label: this.$t("list.state"),
          value: "statusName",
        },
        {
          label: "处理记录ID",
          value: "parkOrderAppealId",
          width: "180",
        },
        {
          label: this.$t("list.processing_time"),
          value: "handleTime",
          width: "180",
        },
        {
          label: this.$t("list.Processing_personnel"),
          value: "handleOperatorName",
        },
        {
          label: "业务订单类型",
          value: "businessTypeName",
          width: "120",
        },
        {
          label: "更改项",
          value: "appealTypeName",
          width: "120",
        },
        {
          label: "更改初始值",
          value: "originValue",
          width: "120",
        },
        {
          label: "更改更新值",
          value: "currentValue",
          width: "120",
        },
      ],
      tablePrepaid: [
        { label: this.$t("list.order_ID"), value: "preOrderId", width: "" },
        { label: this.$t("list.payment_time"), value: "payTime", width: "" },
        {
          label: this.$t("list.prepaid_amount"),
          value: "preMoney",
          width: "",
          formatter: (row, column) => {
            return row.preMoney == 0 ? "0元" : row.preMoney / 100 + "元";
          },
        },

        {
          label: this.$t("list.Deducted_amount"),
          value: "deductMoney",
          width: "",
          formatter: (row, column) => {
            return row.deductMoney == 0 ? "0元" : row.deductMoney / 100 + "元";
          },
        },
        {
          label: this.$t("list.refund_amount"),
          value: "refundMoney",
          width: "",
          formatter: (row, column) => {
            return row.refundMoney == 0 ? "0元" : row.refundMoney / 100 + "元";
          },
        },
        { label: this.$t("list.Payment_channels"), value: "channelTypeDesc", width: "" },
        { label: this.$t("list.Payment_devices"), value: "devTypeDesc", width: "" },
        { label: this.$t("list.Operator"), value: "operatorName", width: "" },
        { label: this.$t("list.Payment_yard"), value: "payParkName", width: "" },
        { label: this.$t("list.Third_party_transaction_ID"), value: "tradeNo", width: "" },
      ],
      tablePrepaiddata: [],
      tableOrder: [
        { label: this.$t("list.order_ID"), value: "payOrderId", width: "" },
        { label: this.$t("list.entry_time"), value: "entryTime", width: "" },
        // { label: this.$t("list.Appearance_time"), value: "exitTime", width: "" },
        { label: this.$t("list.billable_hours"), value: "exitTime", width: "" },
        { label: this.$t("list.payment_time"), value: "payTime", width: "" },
        {
          label: this.$t("list.amount_receivable"),
          value: "shouldPay",
          width: "",
          formatter: (row, column) => {
            return row.shouldPay == 0 ? "0元" : row.shouldPay / 100 + "元";
          },
        },
        {
          label: this.$t("list.preferential_amount"),
          value: "agioPay",
          width: "",
          formatter: (row, column) => {
            return row.agioPay == 0 ? "0元" : row.agioPay / 100 + "元";
          },
        },
        {
          label: this.$t("list.Pay_amount"),
          value: "money",
          width: "",
          formatter: (row, column) => {
            return row.money == 0 ? "0元" : row.money / 100 + "元";
          },
        },
        {
          label: this.$t("list.Payment_Type"),
          value: "paymentTypeDesc",
          width: "",
        },
        {
          label: this.$t("list.Payment_channels"),
          value: "channelTypeDesc",
          width: "",
        },
        {
          label: this.$t("list.Payment_devices"),
          value: "devTypeDesc",
          width: "",
        },
        { label: this.$t("list.Payer"), value: "operatorName", width: "" },
        {
          label: this.$t("list.Payment_yard"),
          value: "payParkName",
          width: "",
        },
        {
          label: this.$t("list.Third_party_transaction_ID"),
          value: "tradeNo",
          width: "",
        },
      ],
      //          if (row.money) {
      //            return Number((row.money) / 100).toFixed(2)
      //          } else {
      //            return '0.00'
      //          }
      parkRecordIdTable: [
        {
          label: this.$t("list.Refund_method"),
          value: "refundModeName",
        },
        // {
        //   label: "支付方式",
        //   value: "payTypeName"
        // },
        {
          label: this.$t("list.Payment_channels"),
          value: "channelTypeDesc",
        },
        {
          label: this.$t("list.Payment_devices"),
          value: "devTypeDesc",
        },
        {
          label: this.$t("list.refund_reason"),
          value: "refundReasonName",
        },
        {
          label: this.$t("list.Actual_appearance_time"),
          value: "actualExitTime",
          formatter: (row) => {
            if (row.actualExitTime) {
              return this.timestampToTime(row.actualExitTime);
            } else {
              return "";
            }
          },
        },
        {
          label: this.$t("list.refund_amount"),
          value: "actualRefundPay",
          formatter: (row, column) => {
            if (row.actualRefundPay) {
              return Number(row.actualRefundPay / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          label: this.$t("list.applicant"),
          value: "operatorName",
        },
        {
          label: this.$t("list.Application_Time"),
          value: "createdTime",
        },
        {
          label: this.$t("list.Refund_time"),
          value: "refundTime",
          formatter: (row) => {
            if (row.refundTime) {
              return this.timestampToTime(row.refundTime);
            } else {
              return "";
            }
          },
        },
      ],
      operationTable: [
        {
          label: this.$t("list.Operation_type"),
          value: "typeDesc",
          // formatter: (row) => {
          //   if (row.type === 1) {
          //     return "车辆入场";
          //   } else if (row.type === 0) {
          //     return "车辆出场";
          //   } else if (row.type === 5) {
          //     return "出场地磁信号";
          //   } else if (row.type === 6) {
          //     return "入场地磁信号";
          //   } else {
          //     return "其他";
          //   }
          // },
        },
        {
          label: this.$t("list.Operation_source"),
          value: "dataSourceName",
        },
        {
          label: this.$t("list.Operator_or_Equipment"),
          value: "operator",
          formatter: (row) => {
            return row.operator;
          },
        },
        {
          label: this.$t("list.Operation_time"),
          value: "operateTime",
        },
      ],
      operationData: [],
      tableData: [],
      parkDetail: [],
      parkRecordId: 0,
      parkRecordIdData: [],
      page: 1,
      size: 15,
      areaIds: "",
      formInline: {
        dataSource: 1, //	数据来源1:路内，2:路外
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        parkType: "-1",
        plateNumber: "",
        entryOrExit: 1,
        startTime: "",
        endTime: "",
        mobile: "",
        parkRecordIds: "",
        berthCode: "",
        recordState: "",
      },
      totalObj: {},
    };
  },
  computed: {
    defaultRangeValue() {
      return [
        {
          typeNumber: this.formInline.plateNumber,
          warntip: "请输入您查询的车牌号/泊位号",
        },
        {
          typeNumber: this.formInline.berthCode,
          warntip: "请输入您查询的车牌号/泊位号",
        },
        {
          typeNumber: this.formInline.berthCode,
          warntip: "请输入您查询的泊位号",
        },
      ];
    },
    exportData() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      let opt = {
        name: "停车记录查询",
        parkId: this.formInline.parkId,
        recordCode: this.formInline.parkRecordIds, // 停车记录编码
        areaIds: areaId,
        carId: this.formInline.carId,
        entryOrExit: this.formInline.entryOrExit,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        operationId: this.formInline.operationId,
        mobile: this.formInline.mobile,
        dataSource: 1, //	数据来源1:路内，2:路外
        berthCode: this.formInline.berthCode,
        recordState: this.formInline.recordState,
      };

      return {
        url: "/acb/2.0/parkRecord/exportParkRecord",
        methods: "post",
        data: opt,
      };
    },
  },
  methods: {
    dataTime(data) {
      if (data) {
        let one = data.slice(0, 10);
        let two = data.slice(11, 19);
        return one + "<br />" + two;
      }
    },
    exportHandler() {
      if (this.formInline.startTime == "" && this.formInline.endTime == "") {
        this.$message({
          message: "请选择时间段",
          type: "warning",
        });
        return;
      }
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      let opt = {
        name: "停车记录查询",
        parkId: this.formInline.parkId,
        recordCode: this.formInline.parkRecordIds, // 停车记录编码
        areaIds: areaId,
        carId: this.formInline.carId,
        entryOrExit: this.formInline.entryOrExit,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        operationId: this.formInline.operationId,
        mobile: this.formInline.mobile,
        dataSource: 1, //	数据来源1:路内，2:路外
        berthCode: this.formInline.berthCode,
        recordState: this.formInline.recordState,
      };
      exportExcelNew("/acb/2.0/parkRecord/exportParkRecord", opt, "post");
    },

    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },
    handleAreaChange() {
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      this.areaIds = areaId;
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10
          ? "0" + (nowDate.getMonth() + 1)
          : nowDate.getMonth() + 1;
      let day =
        nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChange(timeArr, shortcutKey) {
      console.log("shortcutKey", shortcutKey);
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
      if (shortcutKey) {
        this.searParkRecordList();
      }
    },
    resetForm() {
      this.$refs["searchWrapper"].clearValidate();
      this.$refs.cascader.clear();
      this.formInline.areaId = "";
      this.formInline.streetId = "";
      this.formInline.operationId = "";
      this.formInline.parkType = "-1";
      this.formInline.plateNumber = "";
      this.formInline.entryOrExit = 1;
      this.formInline.mobile = "";
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      this.$refs.timeRangePicker.resetTime();
      this.$refs.parkInput.setValue();
      this.formInline.parkRecordIds = "";
      this.formInline.berthCode = "";
      this.formInline.recordState = "";
      this.pageNum = 1;
      this.searParkRecordList();
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
      var h =
        date.getHours() < 10
          ? "0" + date.getHours() + ":"
          : date.getHours() + ":";
      var m =
        date.getMinutes() < 10
          ? "0" + date.getMinutes() + ":"
          : date.getMinutes() + ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    refundOrder() {
      this.$axios
        .get("/acb/2.0/refundRecord/list", {
          data: {
            page: this.page,
            size: this.size,
            parkRecordId: this.parkRecordId,
            refundState: 5,
          },
        })
        .then((res) => {
          this.parkRecordIdData = res.value.list;
        });
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    plateNumberSelect(item) {},
    querySearchAsync(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$request(opt);
    },

    handleSelect(item) {
      this.loading = false;
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searParkRecordList();
    },
    onTopClick() {
      window.location.hash = "#order";
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      if (!this.flag) {
        this.searParkRecordList();
      }
    },
    areaChange(item) {
      this.$refs.parkInput.setValue("");
      this.formInline.operationId = "";
      if (!item) {
        this.formInline.streetId = "";
        this.areaList2 = [];
        return;
      }
      this.getChildrenArea(item);
    },
    detailShow(data) {
      this.parkRecordId = data.parkRecordId;
      this.rowData = Object.assign({}, data);
      this.dialogVisible = true;
      this.isPreRecord = !!data.isPreRecord;
      // 历史处理
      this.orderAppeal();
      this.getRsType(data);
      this.getOrder();
      this.refundOrder();
      // 充电订单记录
      // this.getOrderRecord();
      // 充电违规记录
      // this.getChargeRecord();
      // 操作记录
      this.getOperation();
      // 预付费记录
      this.getdetailByParkRecordId();
    },
    orderAppeal() {
      let opt = {
        url: "/acb/2.0/orderAppeal/getByParkRecordId",
        method: "get",
        data: {
          parkRecordId: this.parkRecordId,
        },
        success: (res) => {
          if (res.state == 0) {
            this.operationHistoryData = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    getChildrenArea(item) {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getChildrenArea", {
          data: {
            areaId: item,
          },
        })
        .then((res) => {
          this.areaList2 = res.value;
          this.formInline.streetId = "";
        });
    },
    clildrenHandleCurrentChange(val) {
      this.getOrder(val);
    },
    /* 停车场 */
    parkData(queryString) {
      if (!queryString) {
        this.formInline.parkId = "";
        return;
      }
      // if (this.formInline.areaId == '') {
      //   this.areaList2 = [];
      //   this.formInline.streetId = '';
      // }
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 20,
            operationId: this.formInline.operationId,
            dataSource: 1,
            areaIds:
              this.formInline.streetId != ""
                ? this.formInline.streetId
                : this.formInline.areaId,
            parkName: queryString,
            slaveRelations: "0,1",
          },
        })
        .then((res) => {
          this.parkList = res.value.list;
        });
    },
    /* 获取车场类型 */
    // getPark() {
    //   let opt = {
    //     url: '/acb/2.0/parkType/list',
    //     method: 'get',
    //     data: {},
    //     success: (res) => {
    //       if (res.state == 0) {
    //         this.parkType = res.value;
    //       } else {
    //         this.$alert(res.desc, this.$t('pop_up.Tips'), {
    //           confirmButtonText: this.$t('pop_up.Determine')
    //         });
    //       }
    //     }
    //   };
    //   this.$request(opt);
    // },
    // 出入场类型列表
    getExitType() {
      let opt = {
        url: "/acb/2.0/parkRecord/entryExitType/list",
        method: "get",
        data: {},
        success: (res) => {
          if (res.state == 0) {
            this.ExitTypeList = res.value;
          } else {
            this.$alert("获取出入场类型失败", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 获取停车场详情图片
    getPic(entryOprNum, exitOprNum) {
      this.entryPic = [];
      this.exitPic = [];
      if (entryOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + entryOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.entryPic = this.sortEntryPic(res.value);
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
      if (exitOprNum) {
        let opt = {
          method: "get",
          url: "/acb/2.0/parkRecord/picture/" + exitOprNum,
          success: (res) => {
            if (res.state == 0) {
              this.exitPic = this.sortEntryPic(res.value);
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
        };
        this.$request(opt);
      }
    },
    compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value2 - value1;
      };
    },
    sortEntryPic(arr) {
      return arr.length && arr.sort(this.compare("imageType"));
    },
    clearSearchOpt(data) {
      this.parkData();
    },
    // 获取默认情况下的各种下拉列表
    getParkSpinner() {
      this.$axios.get("/acb/2.0/park/spinner").then((res) => {
        for (let i = 0; i < res.value.parkType.length; i++) {
          this.parkType.push(res.value.parkType[i]);
        }
      });
    },
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        // var time =
        //   new Date(this.formInline.endTime) -
        //   new Date(this.formInline.startTime);
        // time = time / (24 * 60 * 60 * 1000);
        // if (this.formInline.plateNumber == "" && time > 360) {
        //   this.$alert("请缩小搜索范围，您可输入车牌号或搜索时间范围不超过31天");
        //   return false;
        // } else {
        return true;
        // }
      } else {
        this.$alert("时间段不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    // 初始化用户区域下拉列表
    getArea() {
      this.$axios.get("/acb/2.0/systems/loginUser/initAreaList").then((res) => {
        if (res.value.depth == 3) {
          this.areaList = res.value.areaList;
          this.formInline.areaId = "";
        } else if (res.value.depth == 4) {
          this.areaList2 = res.value.areaList;
          this.formInline.areaId = "";
        }
      });
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/operation/list",
        data: {
          page: 0,
          pageSize: 0,
          companyName: "",
          bearSide: "",
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    searParkRecordListFun() {
      if (!this.formInline.carId) {
        this.formInline.plateNumber = "";
      }
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      // if (this.formInline.startTime == '' && this.formInline.endTime == '') {
      //   this.$message({
      //     message: '请选择时间段',
      //     type: 'warning'
      //   });
      //   return;
      // }
      this.loading = true;
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/list",
        data: {
          berthCode: this.formInline.berthCode,
          parkRecordIds: this.formInline.parkRecordIds,
          page: this.pageNum,
          pageSize: this.pageSize,
          areaId: areaId,
          parkId: this.formInline.parkId,
          operationId: this.formInline.operationId,
          parkType: this.formInline.parkType,
          entryOrExit: this.formInline.entryOrExit,
          startTime: this.formInline.startTime,
          endTime: this.formInline.endTime,
          plateNumber: this.formInline.plateNumber,
          mobile: this.formInline.mobile,
          carId: this.formInline.carId,
          entryPdaManagerName:
            this.formInline.entryOrExit === 1
              ? this.$route.query.pdaManagerName || ""
              : "", // 入场收费员
          exitPdaManagerName:
            this.formInline.entryOrExit === 2
              ? this.$route.query.pdaManagerName || ""
              : "", // 出场收费员
          recordState: this.formInline.recordState,
        },
        success: (res) => {
          this.loading = false;
          if (res.state === 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
            this.getTotalList(opt.data);
          }
        },
        fail: () => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },

    /**
     * 获取统计结果
     */
    getTotalList(data) {
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/total",
        data,
        success: (res) => {
          if (res.state === 0) {
            this.totalObj = res.value || {
              shouldPay: 0,
              discountMoney: 0,
              shouldPayMoney: 0,
              agioPay: 0,
              actualPay: 0,
              refundMoney: 0,
            };
            this.cardLists.forEach((item) => {
              if (item.title == "停车记录") {
                item.number = this.totalObj.parkRecordNum || 0;
              } else if (item.title == "在场记录") {
                item.number = this.totalObj.inParkRecordNum || 0;
              } else if (item.title == "订单金额") {
                item.number = (this.totalObj.shouldPay / 100).toFixed(2) || 0;
              } else if (item.title == "折扣金额") {
                item.number =
                  (this.totalObj.discountMoney / 100).toFixed(2) || 0;
              } else if (item.title == "应收金额") {
                item.number =
                  (this.totalObj.shouldPayMoney / 100).toFixed(2) || 0;
              } else if (item.title == "优惠金额") {
                item.number = (this.totalObj.agioPay / 100).toFixed(2) || 0;
              } else if (item.title == "实付金额") {
                item.number = (this.totalObj.actualPay / 100).toFixed(2) || 0;
              } else if (item.title == "退款总计") {
                item.number = (this.totalObj.refundMoney / 100).toFixed(2) || 0;
              }
            });
          }
        },
        fail: () => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },

    // 搜索
    searParkRecordList() {
      // 9223372036854775807

      if (this.formInline.plateNumber.length < 3) {
        this.formInline.plateNumber = "";
        this.formInline.carId = "";
        this.loading = false;
      }
      let flag = this.showLog();
      this.$refs["searchWrapper"].validate((v) => {
        if (v) {
          if (flag) {
            // this.loading = true;
            this.flag = false;
            this.$refs.parkInput.setShowVal(this.formInline.parkName);
            this.searParkRecordListFun();
          }
        }
      });
    },
    // 订单记录
    getOrder(pageNum) {
      let page = pageNum || 1;
      let opt = {
        url:
          "/acb/2.0/payOrder/payOrderByParkRecordId/" +
          this.rowData.parkRecordId,
        method: "get",
        data: {
          page: page,
          pageSize: this.pageSize,
          parkRecordId: this.rowData.parkRecordId,
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableOrderdata = res.value;
            this.total1 = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 充电订单记录
    getOrderRecord() {
      let opt = {
        url: "/acb/2.0/elecChargeOrder/queryByParkRecordId",
        method: "get",
        data: {
          parkRecordId: this.rowData.parkRecordId || "1762875296607178848",
        },
        success: (res) => {
          if (res.state == 0) {
            this.chargeOrderList = res.value || [];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 充电违规记录
    getChargeRecord() {
      let opt = {
        url: "/acb/2.0/elecViolationRecord/queryByParkRecordId",
        method: "get",
        data: {
          parkRecordId: this.rowData.parkRecordId || "1762875296607178848",
        },
        success: (res) => {
          if (res.state == 0) {
            this.chargeRecordList = res.value || [];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 操作记录
    getOperation() {
      let opt = {
        url:
          "/acb/2.0/parkRecord/getOcrReocrdByParkRecordId/" +
          this.rowData.parkRecordId,
        method: "get",
        data: {
          // page: 1,
          // pageSize: this.pageSize,
          // parkRecordId: this.rowData.parkRecordId
        },
        success: (res) => {
          if (res.state == 0) {
            this.operationData = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 预付费记录
    getdetailByParkRecordId() {
      let opt = {
        url:
          "/acb/2.0/preOrder/detailByParkRecordId/" + this.rowData.parkRecordId,
        method: "get",
        success: (res) => {
          if (res.state == 0) {
            this.tablePrepaiddata = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    // 停车记录详情
    getRsType({ parkRecordId }) {
      let opt = {
        url: "/acb/2.0/parkRecord/" + parkRecordId,
        method: "get",
        // data: this.formInline,
        success: (res) => {
          if (res.state == 0) {
            this.parkDetail = res.value;
            this.getPic(res.value.entryOprNum, res.value.exitOprNum);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },

    // 导出
    exportFile() {
      console.log(this.formInline, "this.formInline");
      let opt = {};

      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      try {
        if (this.formInline.startTime != "" && this.formInline.endTime != "") {
        }
      } catch (error) {
        this.$message({
          message: "时间段不能为空",
          type: "warning",
        });
      }
      opt = {
        parkId: this.formInline.parkId,
        recordCode: this.formInline.parkRecordIds, // 停车记录编码
        areaIds: areaId,
        carId: this.formInline.carId,
        entryOrExit: this.formInline.entryOrExit,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        operationId: this.formInline.operationId,
        mobile: this.formInline.mobile,
        dataSource: 1, //	数据来源1:路内，2:路外
      };
      // exportExcelNew("/acb/2.0/parkRecord/list/export", opt);
      exportExcelNew("/acb/2.0/parkRecord/exportParkRecord", opt);
    },
  },
  components: {
    // InfoList,
    picDetail,
    myComponent,
    timeRangePick,
    tabCard,
    // exportFile,
  },
  created() {
    this.getArea();
    this.getRoleList();
    this.getExitType();
    // this.parkData()
    this.getParkSpinner();
    if (this.$route.query.memberId) {
      //  this.formInline.startTime = this.$route.query.createdTime ? this.$route.query.createdTime : this.$route.query.bindTime ? this.$route.query.bindTime : dateFormat(new Date(), 'yyyy-MM-dd HH:mm:ss');
      this.formInline.startTime = this.$route.query.bindTime
        ? this.$route.query.bindTime
        : this.$route.query.createdTime
        ? this.$route.query.createdTime
        : dateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
      this.formInline.endTime = dateFormat(new Date(), "yyyy-MM-dd HH:mm:ss");
      this.date1 = this.formInline.startTime;
      this.date2 = this.formInline.endTime;
      if (this.$route.query.plateNumber) {
        this.plateNumber =
          this.$route.query.plateNumber.split(",").length > 1
            ? ""
            : this.$route.query.plateNumber;
      }
      this.formInline.plateNumber = this.plateNumber;
      this.formInline.mobile = this.$route.query.mobile;
      this.flag = true;
      this.searParkRecordListFun();
    }
    if (this.$route.query.pdaManagerName) {
      this.defalutDate = [
        this.$route.query.startTime + " 00:00:00",
        this.$route.query.endTime + " 23:59:59",
      ];
      this.formInline.entryOrExit = Number(this.$route.query.entryOrExit);
      this.formInline.operationId = this.$route.query.operationId;
    } else {
      this.defalutDate = [
        this.dataTimeRest() + " 00:00:00",
        this.dataTimeRest() + " 23:59:59",
      ];
    }
  },
  mounted() {
    this.searParkRecordListFun();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;
  background: #fff;

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.orderNumberStyle {
  margin-right: 2px;
  padding: 3px 5px;
  color: #fff;
  background: #95AEFF;
  border-radius: 4px;
  font-size: 12px;
}

.tableWrapper {
  padding: 0 20px 20px;
}

.total-wrapper {
  padding: 0 20px;
  margin-bottom: 22px;

  .item-wrapper {
    min-width: 88px;
    margin-bottom: 5px;
    margin-right: 16px;
    padding: 6px 16px;
    display: inline-block;
    background: #F4F8FF;
    border-radius: 4px;
  }

  .text {
    line-height: 17px;
    color: #425466;
    font-size: 12px;
  }

  .bold-wrap {
    padding-right: 2px;
    position: relative;
    top: 1px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #0f6eff;
    line-height: 25px;
  }

  .unit {
    color: #6C7293;
    font-size: 12px;
  }
}
</style>
